.container {
  margin-bottom: 16px;
}
.container:last-child {
  margin-bottom: 0;
}
.container p {
  margin-bottom: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 8px 12px 8px;
  background-color: var(--colorTan1);
  border: 1px solid var(--colorTan3);
  border-radius: 8px;
  transition: border-color 125ms ease-in-out;
}
.content:hover {
  border-color: var(--colorTan6);
}

.soloCatalogMode {
  padding: 0;
  background-color: white;
  border: unset;
}

.textContent,
.badgeContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textContent {
  composes: ui11Regular from 'styles/typeography.css';
  color: var(--colorGray8);
}

.catalogImage {
  width: 194px;
  border-radius: 4px;
}

.catalogName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorBlack2);
  margin-bottom: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2; /* Number of lines to show */
}

.noMarginBottom {
  margin-bottom: 0;
}
