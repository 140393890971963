.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorTan2);
  width: 100vw;
  margin-top: -24px;
}

.bannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  max-width: 1184px;
  width: 100%;
  gap: 32px;
  grid-column: 1 / -1;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.textContent {
  composes: ui12Regular from 'styles/typeography.css';
}

.reorderButton {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 0;
}

.subtext {
  color: var(--colorGray8);
  margin-bottom: 0;
}

@media (--viewportTabletMin) {
  .bannerContainer {
    grid-column: 2 / -1;
  }
}
