.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.5rem 2rem 0.5rem;
  text-align: center;
}

.icon {
  color: var(--colorTextVeryMuted);
}

.title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.message {
  color: var(--colorTextSemiMuted);
}
