.filtersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterHeading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 0.75rem;
}

.filterHeading a {
  color: inherit;
}

.divider {
  border-bottom: 1px solid var(--colorGray2);
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.allCatalogsLink {
  display: flex;
  align-items: flex-start;
}

.icon {
  margin: 0.125rem;
}

.allCatalogsLink span {
  margin-left: 0.25rem;
}
