.paymentTermsOfferContainer {
  margin-bottom: 6px;
}

.paymentTermsOfferBadge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  composes: ui11Medium from 'styles/typeography.css';
  color: var(--colorGray11);
  background-color: var(--colorTan3) !important;
}
