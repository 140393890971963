.container {
  display: flex;
  align-items: center;
}

.productSortLabel {
  composes: ui14Medium from 'styles/typeography.css';
  margin-right: 0.75rem;
  white-space: nowrap;
}

@media (--viewportPhoneMin) {
  .productSortLabel {
    display: none;
  }
}
