.paginationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  composes: ui14Medium from '../../styles/typeography.css';
}

.paginationWrapper.showSummary {
  justify-content: space-between;
}

.pages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.link {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 0;
  padding-right: 0;
  min-width: 24px;
  display: flex;
}
.link.default {
  --colorButtonLinkText: var(--colorGray7);
  --colorButtonLinkDisabledText: var(--colorGray10);
}
.link.primary {
  --colorButtonLinkText: var(--colorBlue5);
  --colorButtonLinkDisabledText: var(--colorBlue8);
}

.summary,
.summaryMobileView,
.showPageStatus.primary {
  color: var(--colorGray6);
}

.showPageStatus {
  composes: ui14Medium from '../../styles/typeography.css';
  color: var(--colorGray7);
}

.arrowPrev,
.arrowNext {
  font-size: 16px;
}
.arrowPrev.default,
.arrowNext.default {
  color: var(--colorGray10);
}
.arrowPrev.primary,
.arrowNext.primary {
  color: var(--colorBlue8);
}

@media (--viewportPhoneMax) {
  .paginationWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .summary {
    display: none;
  }
}

@media (--viewportTabletMin) {
  .summaryMobileView {
    display: none;
  }
}
