.filtersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filterHeading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 0.75rem;
}
.field {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;
}
.divider {
  border-bottom: 1px solid var(--colorGray2);
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}
