.freeShippingOverContainer {
  margin-bottom: 0.4rem;
  cursor: pointer;
}

.freeShippingBadge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  composes: ui11Medium from 'styles/typeography.css';
  color: var(--colorGray11);
  background-color: var(--colorTan3) !important;
}

.notClickable {
  cursor: default;
  pointer-events: none;
}

.freeShippingBadge:hover {
  color: var(--colorGray12);
  background-color: var(--colorTan4) !important;
}

.modalContainer {
  max-width: 724px;
  min-width: 20rem;
  background-color: white;
  border-radius: 4px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.modalTitle {
  composes: ui20SemiBold from 'styles/typeography.css';
  margin-bottom: 0;
}

.modalClose {
  color: var(--colorBlack2);
  cursor: pointer;
}

.header {
  margin-bottom: 0;
}

/* Width of catalog cover image - 228.5px */
@media (min-width: 228.5px) {
  .freeShippingBadge {
    white-space: normal;
  }
}

.table {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 1rem;
}

.tableHeadCell:first-child,
.tableCell:first-child {
  width: 60%;
  padding-left: 1.5rem;
  border-left: 0 !important;
}

.tableHeadCell:last-child,
.tableCell:last-child {
  padding-right: 1.5rem;
  border-left: 0 !important;
}

.tableHeadCell,
.tableCell {
  border-radius: 0 !important;
}

.tableCell {
  vertical-align: top !important;
}
