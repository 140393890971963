/* Used for the orders and requests lists */

.table {
  width: calc(100% + var(--tablePadding) + var(--tablePadding));
  border-spacing: 0;
  color: var(--colorTextDefault);
  margin-left: calc(0rem - var(--tablePadding));
}

.table a {
  text-decoration: none;
}

.table a:hover {
  text-decoration: underline;
}

.table th,
.table td {
  vertical-align: middle;
}

.table th {
  padding: var(--tablePadding);
  font-weight: 500;
  text-align: left;
  color: var(--colorTextMuted);
}

.table td {
  padding: 0.75rem 0.75rem;
  border-bottom: 1px solid #eaeaea;
}

.table td:first-child {
  border-left: 1px solid #eaeaea;
}

.table td:last-child {
  border-right: 1px solid #eaeaea;
}

/* Cell alignment */

.alignRight {
  text-align: right !important;
}

.alignCenter {
  text-align: center !important;
}

.alignTop td {
  vertical-align: top !important;
}

/* Row */

.table tbody > tr {
  background-color: white;
  border: 1px solid red;
  overflow: hidden;
}

.table tbody > tr:hover {
  background-color: #fbfbfb;
}

/* Table Border and rounded corners */

.table tbody > tr:first-child td {
  border-top: 1px solid #eaeaea;
}

.table tbody > tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

.table tbody > tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

.table tbody > tr:last-child td {
  border-bottom: 1px solid #eaeaea;
}

.table tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.table tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
