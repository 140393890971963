.sellerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.bannerImage {
  position: relative;
  overflow: hidden;
  width: 100vw;
  padding-top: 27%; /* Current recomendation to sellers is a 1500x400 pixel image file */
}

.bannerImage > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 92rem) {
  .bannerImage {
    width: 92rem;
    border-radius: 0 0 0.25rem 0.25rem;
  }
}

/* Pricing Requests */

.pricingRequestsContainer {
  grid-column: 1 / -1;
  padding-bottom: 2rem;
}

@media (--viewportTabletLandscapeMin) {
  .pricingRequestsContainer {
    grid-column: 2 / -1;
  }
}

@media (--viewportTabletMin) {
  .inProgressOrderBannerContainer {
    padding-left: unset;
    padding-right: unset;
  }
  .inProgressOrderBanner {
    padding: 0 48px 0 36%;
  }
}

@media screen and (min-width: 1297px) {
  .inProgressOrderBanner {
    padding: unset;
  }
  .inProgressOrderBannerContent {
    padding-left: 245px;
  }
}
@media screen and (min-width: 1320px) {
  .inProgressOrderBanner {
    padding: unset;
  }
  .inProgressOrderBannerContent {
    padding-left: 245px;
  }
}
@media (min-width: 1472px) {
  .inProgressOrderBanner {
    padding: unset;
  }
  .inProgressOrderBannerContent {
    padding-left: 245px;
  }
}

/* Products Grid */

.productsContainer {
  min-height: 100vh; /* This avoids jerky scrolling & reflowing when products are filtered and products are being fetched */
  padding-top: 2rem;
  padding-bottom: 4rem;
  border-top: 1px solid var(--colorGray2);
}

.sideBarContainer {
  display: none;
}

@media (--viewportTabletMin) {
  .sideBarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.productGridContainer {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
}

@media (--viewportTabletMin) {
  .productGridContainer {
    grid-column: 2 / -1;
  }
}

/*
 * Search and Sort
 */

.productGridHeader {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.productSearchSort {
  display: flex;
  flex-direction: column;
}

.productSearch {
  flex-grow: 1;
  margin-bottom: 1rem;
}

.productModalSidebarAndSort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (--viewportTabletMin) {
  .productSearchSort {
    flex-direction: row;
  }

  .productSearch {
    margin-bottom: 0;
  }

  .productModalSidebarToggle {
    display: none;
  }

  .productModalSidebarAndSort {
    margin-left: 2rem;
  }
}

.filterSearchReset {
  padding-top: 1rem;
  composes: ui12Medium from 'styles/typeography.css';
}

.filterSearchReset > a {
  padding-right: 1rem;
  cursor: pointer;
}

/*
 * Product Grid
 *
 */

.productGrid {
  display: grid;
  gap: 2rem;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1 / -1;
}

.pagination {
  flex-direction: column !important;
  gap: 1rem;
  margin-top: 74px;
  justify-content: flex-start !important;
}

@media (--viewportTabletMin) {
  .productGrid {
    column-gap: 2rem;
    row-gap: 2rem;
    padding: 0;
    grid-column: 2 / -1;
  }
  .pagination {
    flex-direction: row !important;
    gap: 2rem;
  }
}

/* (min-width: 1024px) */
@media (--viewportTabletLandscapeMin) {
  .productGrid {
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .inProgressOrderBanner {
    padding: unset;
    padding-right: 48px;
  }
  .inProgressOrderBannerContent {
    padding-left: 30%;
  }
}

@media (min-width: 1280px) {
  .productGrid {
    grid-template-columns: repeat(4, 1fr);
  }
  .inProgressOrderBanner {
    padding: unset;
    width: 100vw;
  }
  .inProgressOrderBannerContent {
    padding-left: 245px;
  }
}

.progressSpinner {
  width: 50px;
}
